<!--
  功能：功能描述:邮件附件展示
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2024年03月04日 09:43:55
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div v-if="enclosureList && enclosureList.length" class="mail-detail-attach">
    <div v-if="showTitle" class="attach-title">
      <i class="iconfont icon-biezhen"></i>
      <span>
        {{ `${$t('placeholder.appendix')}(${enclosureList.length})` }}
      </span>
      <el-button
        type="text"
        :disabled="enclosureList.some((s) => !s.enclosureUrl)"
        @click="handleDownload(enclosureList)"
      >
        <!-- 全部下载 -->
        {{ $t('other.Downloadall') }}
      </el-button>
    </div>
    <div v-if="enclosureList && enclosureList.length" class="file-wrapper">
      <div
        v-for="(item, index) in enclosureList"
        :key="index"
        class="file-item"
      >
        <i class="iconfont icon-biezhen"></i>
        <el-tooltip
          class="item"
          effect="dark"
          :content="item.enclosureName"
          placement="top"
        >
          <span class="title">{{ item.enclosureName }}</span>
        </el-tooltip>
        <router-link
          v-if="item.enclosureUrl && !item.isError"
          class="preview"
          :to="`/preview-file/index?name=${encode(item.enclosureName)}&url=${
            item.enclosureUrl
          }&suffix=${item.suffix}`"
          target="_blank"
          @click.native.stop
        >
          {{ $t('other.preview') }}
        </router-link>

        <span v-if="item.enclosureUrl" class="size">
          {{ item.enclosureSize | sizeFilter }}
        </span>

        <i
          v-if="!item.enclosureUrl && !item.isError"
          class="el-icon-loading fs16"
        ></i>

        <i
          v-if="item.isError"
          class="el-icon-refresh-right fs16"
          @click="processFiles([item], 1)"
        ></i>
        <i
          v-if="showClose"
          class="el-icon-close fs16 ml10"
          @click="deleteFile(index)"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
  import { processFile } from '@/views/mail/my-mail/utils/file'
  import { encode } from 'kits'

  export default {
    name: 'MailFile',
    props: {
      // 展示附件title
      showTitle: {
        type: Boolean,
        default: true,
      },
      showClose: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        enclosureList: [],
        encode: encode, //转码
      }
    },

    methods: {
      // 下载文件
      handleDownload(fileArr) {
        fileArr = fileArr.filter((file) => file.enclosureUrl)
        fileArr.forEach((item, index) => {
          if (item.enclosureUrl.includes('http')) {
            this.download(item.enclosureUrl, item.enclosureName)
          } else {
            this.download(
              'https://weiwo-erp.oss-cn-shanghai.aliyuncs.com/' +
                item.enclosureUrl,
              item.enclosureName
            )
          }
        })
      },
      download(url, filename) {
        /**
         * 获取 blob
         * @param  {String} url 目标文件地址
         * @return {cb}
         */

        function getBlob(url, cb) {
          var xhr = new XMLHttpRequest()
          xhr.open('GET', url, true)
          xhr.responseType = 'blob'
          xhr.onload = function () {
            if (xhr.status === 200) {
              cb(xhr.response)
            }
          }
          xhr.send()
        }

        /**
         * 保存
         * @param  {Blob} blob
         * @param  {String} filename 想要保存的文件名称
         */

        function saveAs(blob, filename) {
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, filename)
          } else {
            var link = document.createElement('a')
            var body = document.querySelector('body')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            // fix Firefox
            link.style.display = 'none'
            body.appendChild(link)
            link.click()
            body.removeChild(link)
            window.URL.revokeObjectURL(link.href)
          }
        }

        getBlob(url, function (blob) {
          saveAs(blob, filename)
        })
      },

      showFiles(files, isProcess) {
        this.enclosureList = files
        if (isProcess !== false) {
          this.processFiles(this.enclosureList, 3)
        }
      },

      // 处理附件
      processFiles(enclosureList, maxRetries) {
        if (enclosureList.length > 0) {
          enclosureList
            .filter((s) => !s.enclosureUrl)
            .forEach((file) => {
              processFile(file, this, maxRetries)
            })
        }
      },

      deleteFile(index) {
        this.enclosureList.splice(index, 1)
      },
    },
  }
</script>

<style scoped lang="scss">
  .mail-detail-attach {
    padding: 20px 20px 0;
    box-sizing: border-box;

    & > div.attach-title {
      i {
        color: #fa6400;
        font-size: 14px;
      }

      & > span {
        font-size: 14px;
        font-weight: bold;
        color: #303030;
        margin: 0 10px;
      }
    }

    & > div.file-wrapper {
      display: flex;
      flex-wrap: wrap;

      .file-item {
        display: flex;
        align-items: center;
      }

      & > div {
        padding: 0 12px;
        background: #f5f7fa;
        border-radius: 3px;
        border: 1px solid #ebeef5;
        font-size: 12px;
        margin-right: 10px;
        line-height: 30px;
        margin-bottom: 10px;
        cursor: pointer;
        width: 200px;
        display: flex;

        i {
          color: #fa6400;
          font-size: 12px;
        }

        .title {
          flex: 1;
          width: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #303030;
          line-height: 30px;
          margin-left: 10px;
        }

        .preview {
          color: #1890ff;
          margin-left: 10px;
        }

        .size {
          color: #777;
          margin-left: 10px;
        }
      }
    }
  }

  .fs16 {
    font-size: 16px !important;
  }

  .ml10 {
    margin-left: 10px;
  }

  .el-icon-loading {
    color: #1890ff !important;
  }

  ::deep .el-loading-spinner {
    margin-top: -14px !important;
  }
</style>
